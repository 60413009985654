<vsf-layout>
    <vsf-layout-header>
        <div class="bg-gradient-to-r from-zinc-600 to-zinc-800 shadow-lg transform shadow-xl">
            <div class="bg-zinc-100 text-gray-600 shadow-inner text-center text-sm py-2 px-2 xl:px-0">
                <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-between">
                    <div>
                        <p class="hidden sm:block">
                            Exclusive: Get a 5% discount on every purchase!
                            <a
                                href=""
                                target="_blank"
                                class="underline"
                            >
                                
                            </a>
                        </p>
                    </div>
                    <div>
                        <vsf-account-link class=""></vsf-account-link>
                    </div>
                </div>
            </div>
            <div class="max-w-6xl mx-2 py-2 md:mx-auto bg:white flex items-center space-x-4">
                <vsf-mobile-menu-toggle class="md:hidden"></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="l">
                    <img src="assets/edushop-full-logo-big.webp" class="w-20" alt="Crystal edushop">
                </a>
                <vsf-collections-menu class="hidden md:block text-white"></vsf-collections-menu>
                <vsf-product-search-bar class="flex-1"></vsf-product-search-bar>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile [visible]="mobileNavVisible$ | async" class="z-10"></vsf-collections-menu-mobile>
    <vsf-cart-drawer [visible]="cartDrawerVisible$ | async" (close)="closeCartDrawer()"></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer>
        <footer
            class="mt-24 border-t bg-gray-50"
            aria-labelledby="footer-heading"
        >
            <h2 id="footer-heading" class="sr-only">
                Footer
            </h2>
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Shop
                                </h3>
                                <ul role="list" class="mt-4 space-y-4">
                                    <li *ngFor="let item of topCollections$ | async">
                                        <a
                                            [routerLink]="['/category', item.slug]"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-12 md:mt-0">
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Support
                                </h3>
                                <ul role="list" class="mt-4 space-y-4">
                                    <li *ngFor="let item of navigation.support">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                                    Company
                                </h3>
                                <ul role="list" class="mt-4 space-y-4">
                                    <li *ngFor="let item of navigation.company">
                                        <a
                                            [href]="item.href"
                                            class="text-base text-gray-500 hover:text-gray-600"
                                        >
                                            {{item.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 xl:mt-0">
                        <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                            Subscribe to our newsletter
                        </h3>
                        <p class="mt-4 text-base text-gray-500">
                            Be the first to know about exclusive offers & deals.
                        </p>
                        <form class="mt-4 sm:flex sm:max-w-md">
                            <label htmlFor="email-address" class="sr-only">
                                Email address
                            </label>
                            <input
                                type="email"
                                name="email-address"
                                id="email-address"
                                autoComplete="email"
                                required
                                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                placeholder="Enter your email"
                            />
                            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="mt-8 border-t pt-8 flex flex-col items-center text-center">
                    <a
                        class="text-center flex items-center space-x-4 font-medium text-gray-500 hover:text-gray-700"
                        href="crystaledtech.com"
                        target="_blank"
                    >
                        <span>Powered by Crystal</span>
                    </a>
                </div>
            </div>
        </footer>
    </vsf-layout-footer>

</vsf-layout>
